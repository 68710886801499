import React, { useState, useEffect } from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/layout"

export default function OwnerList() {
  const [widget, setWidget] = useState()
  useEffect(() => {
    // get data from GitHub api

    fetch(
      "https://widget.datablocks.se/api/rose/widgets/owner-list?token=52a22f07-8d0f-48da-8419-5f90e92b4170"
    )
      .then(function (response) {
        // The API call was successful!
        return response.text()
      })
      .then(function (html) {
        // This is the HTML from our response as a text string
        setWidget(html)
      })

      .catch(function (err) {
        // There was an error
        console.warn("Something went wrong.", err)
      })
  }, [])

  const result = widget

  return (
    <div>
      <div className="mfn-widget">
        <div className="text-base">
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </div>
        <div className="py-2 space-y-2">
          <p>*Both private and through company</p>
          <p>
            **Sumar is an Iceland based company controlled by Aztiq which is
            industry-wide known pharma focused investment firm.
          </p>
        </div>
      </div>
    </div>
  )
}
