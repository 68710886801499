import React from "react"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import OwnerList from "../../components/investors/owner-list"
import StockChart from "../../components/investors/StockChart"
import StockTable from "../../components/investors/StockTable"
import { Tab } from "@headlessui/react"

import OwnerTypeDist from "../../components/investors/OwnerTypeDist"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/seo"

export default function StockInformationPage() {
  const pageTitle = "Stock Information"
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className="max-w-5xl mx-auto text-base py-8 md:pt-16 px-8">
        <PageHeader text="Stock Information" />
        <div className="space-y-16 stock-widgets">
          <div>
            <h4 className="text-center text-2xl font-bold my-8">Stock Chart</h4>
            <StockChart />
          </div>
          <div>
            <h4 className="text-center text-2xl font-bold my-8">Stock Table</h4>
            <StockTable />
          </div>
          <div style={{ minHeight: "600px" }}>
            <h4 className="text-center text-2xl font-bold my-8">Owner List</h4>
            <OwnerList />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
